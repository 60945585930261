import React from "react"
import {Link} from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found"/>
        <div style={{
            minHeight: `95vh`, display: `flex`,
            background: `rgb(133, 74, 183)`, color: `#fff`,
            justifyContent: `center`, flexDirection: `column`, alignItems: `center`
        }}>
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            <Link to={'/'} style={{
                display: `inline-block`, marginTop: 30,
                color: `#fff`, border: `2px solid #fff`,
                padding: `12px 30px`, borderRadius: 80,
            }}>Go Home</Link>
        </div>
    </Layout>
)

export default NotFoundPage
